import firebase from "@/plugins/firebase";

export default {
  methods: {
    async onSignOut() {
      await this.$store.dispatch("setUserID", false);
      await firebase.auth().signOut();
      this.$toast.open({
        message: "Sign Out Successful",
        type: "success",
      });
    },
  },
};
