<template>
  <v-container>
    <!-- toolbar -->
    <v-row>
      <v-col cols="12">
        <v-toolbar dense color="primary" dark flat rounded>
          <v-toolbar-title>Create a Custom Travel Request</v-toolbar-title>
          <v-spacer></v-spacer>

          <!-- home/dashboard btn -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                depressed
                :loading="loading"
                :disabled="loading"
                dark
                v-bind="attrs"
                v-on="on"
                @click="$router.push('/dashboard')"
              >
                <v-icon>mdi-home-outline</v-icon>
              </v-btn>
            </template>
            <span>Home</span>
          </v-tooltip>
          <!-- home/dashboard btn -->

          <!-- sign out btn -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                :loading="loading"
                :disabled="loading"
                depressed
                dark
                v-bind="attrs"
                v-on="on"
                @click="onSignOut"
              >
                <v-icon>mdi-logout-variant</v-icon>
              </v-btn>
            </template>
            <span>Sign Out</span>
          </v-tooltip>
          <!-- sign out btn -->

          <!-- dark mode button -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                :loading="loading"
                :disabled="loading"
                depressed
                dark
                v-bind="attrs"
                v-on="on"
                @click="$vuetify.theme.dark = !$vuetify.theme.dark"
              >
                <v-icon>{{
                  $vuetify.theme.dark ? "mdi-brightness-4" : "mdi-brightness-6"
                }}</v-icon>
              </v-btn>
            </template>
            <span>Dark Mode</span>
          </v-tooltip>
          <!-- dark mode button -->

          <!-- notifications button -->
          <v-menu
            left
            bottom
            offset-y
            transition="slide-y-transition"
            v-if="userAccount.newDashboardMessageNotification"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                depressed
                color="primary"
                v-bind="attrs"
                v-on="on"
                :loading="loading"
                :disabled="loading"
              >
                <v-badge color="pink">
                  <v-icon>mdi-bell</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <v-list dense :class="$vuetify.theme.dark ? '' : 'grey lighten-3'">
              <v-list-item
                :class="$vuetify.theme.dark ? '' : 'grey lighten-3'"
                link
                @click="$router.push('/dashboard')"
                v-if="userAccount.newDashboardMessageNotification"
              >
                <v-list-item-title
                  >You have a new message. Click here to review your
                  timeline.</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- notifications button -->
        </v-toolbar>
      </v-col>
    </v-row>
    <!-- toolbar -->
    <!-- information -->
    <v-row>
      <!-- video -->
      <v-col cols="12" md="6">
        <v-card :loading="loading" outlined>
          <v-card-text class="text--primary"> video here </v-card-text>
        </v-card>
      </v-col>
      <!-- video -->
      <!-- instructions -->
      <v-col cols="12" md="6">
        <v-card :loading="loading" outlined>
          <v-card-text class="text--primary body-1 font-weight-bold">
            <p>Please note that inventory is updated very frequently.</p>
            <p>
              Our booking team has access to the same inventory options that are
              available to you. We recommend that you
              <router-link to="/browse"
                >review all the available inventory options here</router-link
              >
              before submitting a custom request.
            </p>
            <p>
              If you'd like to proceed, please use the form below to submit a
              custom travel request. Our booking team will respond within 7
              business days.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- instructions -->
    </v-row>
    <!-- information -->
    <!-- form -->
    <v-row>
      <v-col cols="12">
        <v-card :loading="loading" outlined>
          <v-card-text class="text--primary pa-8">
            <v-form ref="form" @submit.prevent="onSubmit">
              <v-row>
                <!-- other name -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="otherName"
                    :rules="[textRules.required]"
                    :loading="loading"
                    :disabled="loading"
                    dense
                    label="Other Name(s) That May Be Listed For Yourself (or Enter None) *"
                  ></v-text-field
                ></v-col>
                <!-- other name -->
                <!-- yearOfBirth -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="birthYear"
                    :loading="loading"
                    :disabled="loading"
                    :rules="[textRules.required, textRules.fourNumbers]"
                    dense
                    label="Yea of Birth *"
                    type="number"
                    @keypress="limitBirthYearValues($event)"
                  ></v-text-field
                ></v-col>
                <!-- yearOfBirth -->
                <!-- mailingAddress -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="mailingAddress"
                    :loading="loading"
                    :disabled="loading"
                    :rules="[textRules.required]"
                    dense
                    label="Mailing Address *"
                  ></v-text-field
                ></v-col>
                <!-- mailingAddress -->

                <!-- numberOfAdults -->
                <v-col cols="12" md="6">
                  <v-select
                    v-model="numberOfAdults"
                    :loading="loading"
                    :disabled="loading"
                    :rules="[textRules.required]"
                    dense
                    :items="[1, 2, 3, 4, 5, 6, 7, 8]"
                    label="Number of Adults (Including Yourself) *"
                  ></v-select>
                </v-col>
                <!-- numberOfAdults -->
                <!-- numberOfChildren -->
                <v-col cols="12" md="6">
                  <v-select
                    v-model="numberOfChildren"
                    :loading="loading"
                    :disabled="loading"
                    :rules="[textRules.required]"
                    dense
                    :items="['0', '1', '2', '3', '4']"
                    label="Number of Children*"
                    hint="Children are considered ages 12 &amp; under by most resorts"
                    persistent-hint
                  ></v-select>
                </v-col>
                <!-- numberOfChildren -->
                <!-- checkInDate -->
                <v-col cols="12" md="6">
                  <v-text-field
                    :loading="loading"
                    :disabled="loading"
                    :rules="[textRules.required]"
                    label="Check In Date *"
                    @click="showCheckInDatePicker = !showCheckInDatePicker"
                    dense
                    readonly
                    :value="formatDate(checkInDate)"
                    append-icon="mdi-calendar-cursor"
                  ></v-text-field>
                  <v-expand-transition>
                    <div
                      class="pa-6 grey lighten-3"
                      style="border-radius: 4px"
                      v-if="showCheckInDatePicker"
                    >
                      <v-date-picker
                        full-width
                        v-model="checkInDate"
                        @change="showCheckInDatePicker = false"
                      ></v-date-picker>
                    </div>
                  </v-expand-transition>
                </v-col>
                <!-- checkInDate -->
                <!-- alternativeCheckInDate1 -->
                <v-col cols="12" md="6">
                  <v-text-field
                    :loading="loading"
                    :disabled="loading"
                    :rules="[textRules.required]"
                    label="Alternative Check In Date 1 *"
                    @click="
                      showAlternativeDatePicker1 = !showAlternativeDatePicker1
                    "
                    dense
                    readonly
                    :value="formatDate(alternativeCheckInDate1)"
                    append-icon="mdi-calendar-cursor"
                  ></v-text-field>
                  <v-expand-transition>
                    <div
                      class="pa-6 grey lighten-3"
                      style="border-radius: 4px"
                      v-if="showAlternativeDatePicker1"
                    >
                      <v-date-picker
                        full-width
                        v-model="alternativeCheckInDate1"
                        @change="showAlternativeDatePicker1 = false"
                      ></v-date-picker>
                    </div>
                  </v-expand-transition>
                </v-col>
                <!-- alternativeCheckInDate1 -->
                <!-- alternativeCheckInDate2 -->
                <v-col cols="12" md="6">
                  <v-text-field
                    :loading="loading"
                    :disabled="loading"
                    :rules="[textRules.required]"
                    label="Alternative Check In Date 2 *"
                    @click="
                      showAlternativeDatePicker2 = !showAlternativeDatePicker2
                    "
                    dense
                    readonly
                    :value="formatDate(alternativeCheckInDate2)"
                    append-icon="mdi-calendar-cursor"
                  ></v-text-field>
                  <v-expand-transition>
                    <div
                      class="pa-6 grey lighten-3"
                      style="border-radius: 4px"
                      v-if="showAlternativeDatePicker2"
                    >
                      <v-date-picker
                        full-width
                        v-model="alternativeCheckInDate2"
                        @change="showAlternativeDatePicker2 = false"
                      ></v-date-picker>
                    </div>
                  </v-expand-transition>
                </v-col>
                <!-- alternativeCheckInDate2 -->

                <!-- travelPartyInfo -->
                <v-col cols="12" md="6">
                  <h4 class="mb-4">Describe Your Travel Party</h4>
                  <v-textarea
                    outlined
                    v-model="specialRequests"
                    :loading="loading"
                    :disabled="loading"
                    dense
                    label="Travel Party Information"
                    hint="Please include information about your travel party here. For example, 2 couples traveling together, or 2 adults with their children, etc."
                    persistent-hint
                  ></v-textarea>
                </v-col>
                <!-- travelPartyInfo -->

                <!-- specialRequests -->
                <v-col cols="12" md="6">
                  <h4 class="mb-4">
                    Special Requests, Special Needs, or Alternative Destinations
                  </h4>
                  <v-textarea
                    outlined
                    v-model="specialRequests"
                    :loading="loading"
                    :disabled="loading"
                    dense
                    hint="If your package contains multiple destinations, or an alternative destination, please enter your preferred destination here."
                    persistent-hint
                  ></v-textarea>
                  <p class="body-2 mt-4">
                    We are happy to help make this a memorable travel
                    experience! Please list any additional information pertinent
                    to this trip. If for example, you would like to add an extra
                    bedroom, or someone in your party has special needs, please
                    provide details for your request in the special requests
                    field. We will do our best to address you request when
                    preparing the list of available resorts.
                  </p>
                </v-col>
                <!-- specialRequests -->

                <!-- acceptTerms -->
                <v-col cols="12" md="10">
                  <v-checkbox
                    v-model="agreedToTerms"
                    :loading="loading"
                    :disabled="loading"
                    :rules="[checkboxRules.required]"
                    label="* I understand it will take up to 7 business days for an Availability Sheet to be received at my email address (finding the right unit to fulfill a special needs request may require more than 7 business days)"
                    hint="Note: You may need to also check your email spam folder for our Silent Auction Vacations email over the next 7 business days."
                    persistent-hint
                  ></v-checkbox>
                </v-col>
                <!-- acceptTerms -->
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions class="mt-8">
            <v-btn
              color="error"
              outlined
              to="/dashboard"
              :loading="loading"
              :disabled="loading"
              >Cancel</v-btn
            >
            <v-spacer />
            <v-btn
              depressed
              color="primary"
              @click="onSubmit"
              :loading="loading"
              :disabled="loading"
              >Submit Custom Travel Request</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- form -->
  </v-container>
</template>

<script>
const userAccounts = firebase.firestore().collection("userAccounts");
const eventReportsCollection = firebase.firestore().collection("eventReports");

import firebase from "@/plugins/firebase";
import axios from "axios";
import onSignOut from "@/mixins/onSignOut";
import formRules from "@/mixins/formRules";
import formatDate from "@/mixins/formatDate";

export default {
  mixins: [onSignOut, formRules, formatDate],
  data: () => ({
    userAccount: "",
    eventReport: "",
    specificWinner: "",

    showCheckInDatePicker: false,
    showAlternativeDatePicker1: false,
    showAlternativeDatePicker2: false,
    otherName: "",
    birthYear: "",
    mailingAddress: "",
    numberOfAdults: "",
    numberOfChildren: "",
    checkInDate: "",
    alternativeCheckInDate1: "",
    alternativeCheckInDate2: "",
    specialRequests: "",
    agreedToTerms: "",
    travelPartyInfo: "",
  }),
  computed: {
    userID() {
      return this.$store.state.userID;
    },
    loading() {
      return this.$store.state.loading;
    },
  },
  watch: {
    userID: {
      immediate: true,
      async handler(userID) {
        if (!userID) {
          this.$router.push("/");
          return;
        }
        // bind user account
        let userAccount = await this.$bind(
          "userAccount",
          userAccounts.doc(userID)
        );
        // bind user account
        //bind specificWinner && eventReport
        let eventReportsRef = eventReportsCollection.where(
          "winnerPackageIDs",
          "array-contains",
          userAccount.packageID
        );
        let eventReports = await eventReportsRef.get();
        let eventReport = eventReports.docs[0];
        await this.$bind(
          "eventReport",
          eventReportsCollection.doc(eventReport.id)
        );
        this.specificWinner = this.eventReport.winners.filter(
          (i) => i.packageID == userAccount.packageID
        )[0];
        //bind specificWinner && eventReport
      },
    },
  },
  methods: {
    async onSubmit() {
      if (this.$refs.form.validate()) {
        await this.$store.dispatch("setLoading", true);
        let avSheetData = {
          adminHasReplied: false,
          agreedToTerms: this.agreedToTerms,
          alternativeCheckInDate1: this.alternativeCheckInDate1,
          alternativeCheckInDate2: this.alternativeCheckInDate2,
          archived: false,
          birthYear: this.birthYear,
          checkInDate: this.checkInDate,
          dateCreated: new Date().toISOString().substring(0, 10),
          mailingAddress: this.mailingAddress,
          numberOfAdults: this.numberOfAdults,
          numberOfChildren: this.numberOfChildren,
          otherName: this.otherName,
          specialRequests: this.specialRequests,
          travelPartyInfo: this.travelPartyInfo,
          userID: this.userAccount.id,
        };

        // create avSheet ID
        let docID =
          this.userAccount.packageID +
          "-" +
          (this.userAccount.numberOfAVSheets + 1);

        // add avSheet to collection
        await firebase
          .firestore()
          .collection("avSheets")
          .doc(docID)
          .set(avSheetData);

        // send emails to user and booking
        await axios.post("/api/new-av-sheet/", {
          userAccount: this.userAccount,
          specificWinner: this.specificWinner,
          eventReport: this.eventReport,
          avSheetData: avSheetData,
        });

        // update user account
        let timelineItem = {
          adminActionRequired: true,
          archived: false,
          dateCreated: new Date().toISOString().substring(0, 10),
          from: this.userAccount.email,
          message: "",
          readByAdmin: false,
          repliedToByAdmin: false,
          senderActionRequired: false,
          title: "You submitted a custom travel request",
          to: "admin",
          icon: "mdi-wallet-travel",
          color: "primary",
        };

        await firebase
          .firestore()
          .collection("userAccounts")
          .doc(this.userAccount.id)
          .update({
            canBook: false,
            canBrowseInventory: false,
            canSendMessages: true,
            pendingAVSheetCreation: true,
            timeline: firebase.firestore.FieldValue.arrayUnion(timelineItem),
            numberOfAVSheets: firebase.firestore.FieldValue.increment(1),
            avSheets: firebase.firestore.FieldValue.arrayUnion(docID),
          });

        // success
        await this.$store.dispatch("setLoading", false);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "success",
          text: "Request Submitted Successfully",
          timeout: 5000,
        });

        // route to dashboard
        this.$router.push("/dashboard");
        return;
      }
      // form validation error
      await this.$store.dispatch("setLoading", false);
      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "error",
        text: "Please check form for errors",
        timeout: 5000,
      });
      return;
    },
    limitBirthYearValues(e) {
      if (e) {
        let value = e.target.value;
        let charCode = e.charCode;
        let limit = 4;
        // max 10 characters
        if (value != undefined && value.toString().length >= limit) {
          e.preventDefault();
        }
        // not allowing characters 43(+), 45(-), 46(.), 69(E), 101(e)
        if (
          charCode == 43 ||
          charCode == 45 ||
          charCode == 46 ||
          charCode == 69 ||
          charCode == 101
        ) {
          e.preventDefault();
        }
      }
    },
  },
};
</script>