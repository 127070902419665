// receives date
// returns formatted value
// Thursday Jan 28, 2021

import moment from "moment";

export default {
  methods: {
    formatDate(date) {
      if (date) {
        return moment(date).format("ddd MMM DD, YYYY");
      }
    }
  }
}